@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 600;

  &.dim {
    background: rgba(0, 0, 0, 0.2);
  }

  &.confirm {
    padding-top: 64px;
    align-items: flex-start;
  }

  .content {
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15), 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  }
}
