@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.mobile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 0 0 8px 8px;

  .mobile-btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: $color-gray-700;
    background-color: $color-gray-80;
    border: none;
    outline: none;
    cursor: pointer;

    &.submit {
      background: $color-gray-800;
      color: $color-white;
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid $color-gray-300;
  padding: 16px 24px;

  .right-buttons {
    display: flex;
    gap: 8px;
  }

  .btn {
    min-width: 64px;
  }
}
