@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  width: 100%;
  height: 56px;
  border-radius: 8px 8px 0px 0px;
  background: $color-gray-800;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $color-white;
  padding: 16px 24px;
  box-shadow: 0 0 1px $color-gray-800;
}
