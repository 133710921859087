@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  width: 1008px;
  margin: 56px auto 88px auto;
  padding: 0 40px;

  &.isPreview {
    padding-top: 64px;
  }

  &.TABLET {
    width: 768px;
    padding: 0 40px;
  }

  &.MOBILE {
    width: 312px;
    padding-top: 70px;
    padding: 0 0px;

    .form {

      .title {
        @include font('h5');
        margin-bottom: 8px;
      }

      .textarea {
        height: 160px;

        &:disabled {
          @include colors-bg($color-gray-100, $color-gray-100, $color-gray-100);
        }
      }

      .qna-title {
        @include font('b2');
        font-weight: bold;
        margin-bottom: 16px;
      }

      .checkbox {
        .agreement {
          @include font('b4');
          font-weight: bold;

          .link {
            @include font('b5');
          }
        }
      }

      .form-grid-1 {
        margin-bottom: 16px;
      }


      .form-grid-2 {
        flex-direction: column;
        margin-bottom: 0;

        .form-grid-1 {
          margin-right: 0;
        }
      }
    }
  }

  .title {
    display: block;
    @include font('h2');
    margin-bottom: 16px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 40px;
  }

  .form {

    .error {
      margin-top: 4px;
      color: $color-red-600;
    }

    .qna-title {
      @include font('b1');
      margin-bottom: 24px;
      font-weight: 700;
    }

    .form-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-bottom: 8px;

      .text {
        position: relative;
      }

      .require:after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        top: 0;
        right: -9px;
        border-radius: 100%;
        background: $color-green-600;
      }

      .title-length {
        color: $color-gray-600;
      }
    }

    .sub-label {
      margin-top: 4px;
      color: $color-gray-600;
    }

    .select {
      width: 100%;
    }

    .textarea {
      width: 100%;
      height: 240px;
      background: $color-gray-80;
      border-radius: 6px;
      border: 2px solid $color-gray-80;
      font-size: 14px;
      padding: 16px;

      &:disabled {
        color: $color-gray-400;
      }
    }

    .upload-button {
      width: 143px;
      margin-bottom: 20px;
    }

    .checkbox {
      display: flex;
      flex-direction: column;
      margin-bottom: 48px;

      .checkbox-top {
        display: flex;
        align-items: center;
        height: 24px;

      }

      .check-label {
        padding-left: 8px;
        line-height: 24px;
      }

      .agreement {
        @include font('b2');
        font-weight: bold;
      }
    }

    .link {
      margin-left: 24px;
      font-width: 500;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
      color: $color-gray-600;
      background: white;
      position: relative;
      top: 3px;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .email-wrapper {
      position: relative;
      width: 100%;

      .email-field {
        width: 100%;
      }

      .email-list-wrapper {
        position: absolute;
        top: 50px;
        overflow: hidden;
        width: 100%;
        max-height: 200px;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        background: white;
        z-index: 10;

        .email-list {
          margin: 0;
          padding: 8px;
          width: calc(100% + 20px);
          max-height: 200px;
          z-index: 404;
          list-style: none;
          overflow-x: hidden;
          overflow-y: auto;

          > li {
            height: 32px;
            display: flex;
            align-items: center;
            color: $color-gray-600;
            padding: 0 8px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              background: $color-gray-80;
            }
          }
        }
      }
    }

    .form-grid-1 {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 24px;

      .error-message {
        position: absolute;
        display: inline-block;
        color: $color-red-600;
        margin-top: 8px;
      }


      &.margin-48px {
        margin-bottom: 48px;
      }

      &.margin-16px {
        margin-bottom: 16px;
      }

      &.margin-12px {
        margin-bottom: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .form-grid-2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }
}

.option-label {
  display: block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overseas-resident-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}